<template>
  <div class="ellipsis" @click="closeEllipsis">
    <div class="ellipsis_box">
      <div class="ellipsis_top">
        <div class="close_overlay" @click.stop="update">无法进出站</div>
        <div class="close_overlay" @click.stop="toNotice">查看使用须知</div>
      </div>
      <div class="cancel_overlay">
        <van-button type="primary" @click.stop="cancelEllipsis">取消</van-button>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  data () {
    return {
      
    };
  },
  mounted () {
  },
  methods: {
    closeEllipsis () {
      this.$emit('cancelEllipsis');
    },
    //进出站更新
    update () {
      this.$emit('update');
    },
    // 查看使用须知
    toNotice () {
      this.$emit('toNotice');
    },
    cancelEllipsis () {
      this.$emit('cancelEllipsis');
    },
   
  },
};
</script>
<style lang="scss" scoped>
.ellipsis {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 102;
  .ellipsis_box {
    position: fixed;
    bottom: 0.3rem;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    padding: 0.05rem 0.1rem;
    box-sizing: border-box;
    .ellipsis_top {
      background-color: #fff;
      border-radius: 0.08rem;
      margin-bottom: 0.06rem;
      .close_overlay {
        width: 100%;
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        border-bottom: 0.01rem solid #e7e7e7;
        font-size: 0.16rem;
      }
      .close_overlay:last-child {
        border: none;
      }
    }
    .cancel_overlay {
      width: 100%;
      height: 0.5rem;
      /deep/ .van-button {
        width: 100%;
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        background-color: #fff;
        border-radius: 0.08rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        font-size: 0.16rem;
        border: none;
      }
    }
  }
}
</style>
