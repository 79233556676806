<template>
  <div class="outbound-wrap">
    <Ad :channelId="AdChannelId"></Ad>
    <div class="outbound-extrusion">
      <div class="outbound-head">

        <Head @onRecord="onRecord" @jumpService="jumpService" @ellipsisClick="ellipsisClick"></Head>
        <div class="energy" @click="jumpEnergy">
          <div class="energy_img">
            <img :src="energyImg" />
          </div>
          <!-- <div class="energy_text">
            <div class="energyNum">
              <span>能量：</span><span>{{ energyNum }}</span>
            </div>
            <div class="reduction">
              <span>碳减排：</span><span>{{ reduction }}</span>
            </div>
          </div> -->
        </div>
        <div class="outbound-head-leave">
          <img :src="imgYes" alt="" class="outbound-head-img" />
          <div class="outbound-head-show" v-if="!flag">
            <div v-if="netWork">出站成功-{{ name }}站</div>
            <div v-if="!netWork">出站成功</div>
            <div>&nbsp;</div>
          </div>
          <div class="outbound-head-show" v-if="flag">
            <div v-if="netWork">进站成功-{{ name }}站</div>
            <div v-if="!netWork">进站成功</div>
            <div>出站时记得再扫我一次哦</div>
          </div>
          
          <!-- Text ad -->
          <TextAd :channelId="channelIdText1" :refresh="refresh" enableDot @bleStop="bleStop" />
        </div>
        <div class="outbound-head-button">
          <van-button type="border" text="知道了" @click="onClickKnow"></van-button>
        </div>
      </div>
    </div>

    <Ticket :channelId="ticketChannelId" v-if="this.getConfigValue('enable302936Ad') == '1'"></Ticket>
    <div class="btn_box" style="min-height:300px;" :style="{ height: iframeHeight }" ref="iframeBox"
      v-if="this.getConfigValue('enableYsfIframeAd') == '1'">
      <iframe src="" ref="ifr" id="outerPage" frameborder="0" style="width:100%;height:100%;"></iframe>
    </div>
    <Ellipsis v-show="ellipsisShow" @update="update" @toNotice="toNotice" @cancelEllipsis="cancelEllipsis">
    </Ellipsis>

    <van-dialog v-model="followShow" :showConfirmButton="false">
      <div class="follow_box">
        <div class="follow_main">
          关注小程序，不错过乘车及优惠信息
        </div>
        <div class="follow_btn">
          <div class="btn" @click="immediately">立即关注</div>
          <div class="btn1" @click="consider">不再提醒</div>
        </div>
      </div>
    </van-dialog>
    <Footer @jumpCode="jumpCode" @jumpMy="jumpMy" :icon="icon"></Footer>
  </div>
</template>

<script>
import Footer from '../footer/footer';
import Ellipsis from '../ellipsis/index';
import { delLocalData } from '../../common/jssdk';
import { debounce, getQueryString, myLocalStorage } from '../../utils';
import { alreadyFollow } from '@/utils/api';
import { Toast } from 'vant';
import Ad from '@/components/ad';
import TextAd from '@/components/ad/text';
import Ticket from '@/components/ticket';
import { matomoOpen, appId, ysfMarketUrlPattern } from '@/unified';
import { EventBus } from '@/common/event-bus.js';
import { getConfig } from '@/common/config.js';
export default {
  name: 'outboundSuccess',
  components: {
    Footer,
    Ellipsis,
    Ad,
    TextAd,
    Ticket,
  },
  data () {
    return {
      imgYes: require('@/assets/image/yesImg@2x.png'),
      energyImg: require('@/assets/image/geL.png'),
      reduction: '123',
      energyNum: '0',
      flag: false,
      id: '', // 站点id
      name: '', //站点名称
      nameList: [
        // { name: 'name1', id: '123' },
        // { name: 'name2', id: '456' },
        // { name: 'name3', id: '987' },
      ], // 站点列表
      type: '1', // 进出站类型，1进站，0出站
      ellipsisShow: false,
      icon: '1',
      adUrls: [], //广告列表
      netWork: true,
      followShow: false,
      ticketShow: true, //领券模块是否显示
      AdChannelId: '302935',
      ticketChannelId: '302936',
      openId: '',
      iframeHeight: '',
      channelIdText1: 'text_001',
    };
  },

  async created () {
    this.openId = localStorage.getItem('openId');

    if (decodeURIComponent(getQueryString('name'))) {
      this.netWork = true;
      this.name = decodeURIComponent(getQueryString('name')); // 通过站点id获取站点名称
    } else {
      this.netWork = false;
    }

    console.log('00000', getQueryString('type') === '1');
    // this.flag = getQueryString('type') === '1' ? true : false;
    console.log('进站还是出站type', getQueryString('type'));
    if (getQueryString('type') === '1') {
      this.flag = false;
    } else if (getQueryString('type') === '2') {
      this.flag = true;
    } else if (getQueryString('type') === '3') {
      this.flag = false;
    } else {
      this.flag = true;
    }
    console.log(this.flag);

    // 若是出站，删除进站缓存信息
    if (!this.flag) {
      console.log('出站，开始删除日志');
      // delLocalData({ key: 'logIn' });
      // delLocalData({ key: 'qrcodedaySHMQR' });
      // delLocalData({ key: 'qrcodedaySHMQRDateHour' });
    }
    this.id = getQueryString('id');
    // const map = list2Map(this.nameList, 'id');
    // console.log('lalalal---', list2Map(this.nameList, 'id'), map[id]);
    // this.name = map[+this.id].name || '未知站点'; // 通过站点id获取站点名称
  },
  mounted () {
    var _this = this;
    EventBus.$on('PageShow', msg => {
      //document.getElementById('outerPage').src=this.url;
      _this.populateYsfIframeAd();
    });
    window.addEventListener('message', this.iframeHeightBypostmessage);
    _this.populateYsfIframeAd();
  },
  beforeDestroy () {
    window.removeEventListener('message', this.iframeHeightBypostmessage);
  },
  methods: {
    populateYsfIframeAd () {
      if (getConfig('enableYsfIframeAd') == '1') {
        var url = ysfMarketUrlPattern;
        url = url.replace('[appId]', appId);
        url = url.replace('[openId]', encodeURIComponent(getConfig('ysfOpenId')));
        url = url.replace('[stationNo]', this.id);
        url = url.replace('[stationNm]', encodeURIComponent(this.name ? this.name : ''));
        url = url.replace('[lineNo]', (this.id && this.id.length > 2) ? this.id.substr(0, 2) : '');
        url = url.replace('[lineNm]', (this.id && this.id.length > 2) ? this.id.substr(0, 2) : '');

        console.log('打开云闪付iframe广告: ' + url);
        document.getElementById('outerPage').src = url;
        // document.getElementById('outerPage').src = 'http://101.231.114.212:10002/traffic-marketing/html/merchantList.html?appId=71ff0ed8047040ae9507c557868faa2e&openId=Pb/lmpl0Ote48S7O0IrKs4fltQ0ve5BwwfT9npRdyeJWj6zW7qQHOkqlZJJgYI7v&userId=cd20525103605020&opId=C0000456&serviceId=02&stationNo=000001&stationNm=&lineNo=002&lineNm=TEST&direction=01&cityId=4403'
      }
    },
    getConfigValue (key) {
      return getConfig(key);
    },
    iframeHeightBypostmessage (e) {
      console.log(e);
      console.log(e.data);
      console.log('data类型', typeof e.data);
      if (e && e.data) {
        if (typeof e.data == 'string') {
          console.log('data是字符串');
          if (JSON.parse(e.data).code) {
            if (JSON.parse(e.data).code === 'iframeHeight') {
              this.iframeHeight = JSON.parse(e.data).text + 'px';
              console.log(this.iframeHeight);
            } else if (
              JSON.parse(e.data).code === 'couponId' ||
              JSON.parse(e.data).code === 'shopId'
            ) {
              console.log('使用openRNPage跳转云闪付APP原生页面')
              var dest = JSON.parse(e.data).text;
              this.$matomo.trackEvent('出行广告RNPage', dest);
              upsdk.openRNPage({
                dest: dest, // 必传，目标RN页面，示例中表示打开 转账页面（RN实现）
                isFinished: '0', // 可选，默认为‘0’，为‘1’则关闭当前webview
                extraData: {
                  // 可选
                  freePaymentType: '1',
                },
                success: function () {
                  // 成功跳转
                },
                fail: function (msg) {
                  // 失败回调
                },
              });
            } else if (JSON.parse(e.data).code === 'appId') {
              console.log('使用openApplet跳转其他小程序')
              var appId = JSON.parse(e.data).text;
              this.$matomo.trackEvent('出行广告Applet', appId);
              upsdk.openApplet({
                appId: appId, //必填，为16位小程序应用id
                param: '', //可选，拼在小程序首页ur1后的查询参数
                isFinish: 0, //可选，1表示关闭当前页面 非1不做处理，默认为0toLink:"ur1'，//可选，打开小程序时打开指定页，ur1不做编码，ur1要求域名在小程序安全域名范围内，仅支持云闪付9.0以上版本fail:function(code)[ //code=101表示appId为空,code=102表示找不到该应用
                toLink: 'url',
                fail: function (code) {
                  console.fail(code);
                },
              });
            } else if (JSON.parse(e.data).code === 'url') {
              console.log('使用window.location跳转其他外部链接')
              var url = JSON.parse(e.data).text;
              this.$matomo.trackEvent('出行广告url', url);
              upsdk.createWebView({
                url: url,
                isFinish: 0,
              });
            }
          }
        } else {
          console.log('data类型是对象');

        }
      }
    },
    // 跳转首页
    onClickKnow () {
      const followSt = localStorage.getItem('followSt');
      if (this.flag) {
        localStorage.setItem('flag', this.flag);
        this.$router.push({
          name: 'SinglePage',
        });
      } else {
        localStorage.removeItem('flag');
        if (followSt != '1' && localStorage.getItem('noNotifyWhenOut') != '1') {
          // 检查不再提醒标记（noNotifyWhenOut == 1为不再提醒）
          this.followShow = true;
        } else {
          this.$router.push({
            name: 'SinglePage',
          });
        }
      }
    },

    // 立即关注
    immediately () {
      var this_ = this;
      upsdk.collectCurrentApplet({
        success: function (data) {
          // 成功回调 data={"code":"00"}
          // 用户点击了关注，且关注成功了
          console.log('关注成功了', data);
          localStorage.setItem('followSt', '1');
          this_.followShow = false;
          this_.$router.push({
            name: 'SinglePage',
          });
        },
        async fail (error) {
          console.log(error);
          if (error.code == '01') {
            const res = await alreadyFollow(this_.openId);
            console.log(res);
            localStorage.setItem('followSt', '1');
            Toast('已关注');
            this_.followShow = false;
            this_.$router.push({
              name: 'SinglePage',
            });
          } else {
            Toast('关注失败请重试');
          }
        },
      });
    },
    // 再考虑一下
    consider () {
      localStorage.setItem('noNotifyWhenOut', '1');

      this.$router.push({
        name: 'SinglePage',
      });
    },
    // 点击已签约或者关闭中
    jumpCode () {
      this.$router.push({
        name: 'SinglePage',
      });
    },
    jumpMy () {
      this.$router.push({
        name: 'My',
      });
    },

    // 乘车记录
    onRecord () {
      this.$router.push({
        name: 'ScreenPage',
      });
    },
    //跳转客服页面
    jumpService () {
      this.$router.push({
        name: 'Service',
      });
    },
    //点击右上角三个点
    ellipsisClick () {
      this.ellipsisShow = true;
    },
    //进出站更新
    update () {
      this.$router.push({
        name: 'Official',
      });
    },
    // 产看使用须知
    toNotice () {
      this.$router.push({
        name: 'ticketNotes',
      });
    },
    cancelEllipsis () {
      this.ellipsisShow = false;
    },
    // 点击能量跳转
    jumpEnergy () {
      if (matomoOpen && this.$matomo) {
        this.$matomo.trackEvent('adClick', 'green');
      }
      upsdk.openApplet({
        appId: 'a603d3cefaa4cb9c', //必填，为16位小程序应用id
        param: '', //可选，拼在小程序首页ur1后的查询参数
        isFinish: 0, //可选，1表示关闭当前页面 非1不做处理，默认为0toLink:"ur1'，//可选，打开小程序时打开指定页，ur1不做编码，ur1要求域名在小程序安全域名范围内，仅支持云闪付9.0以上版本fail:function(code)[ //code=101表示appId为空,code=102表示找不到该应用
        toLink: 'url',
        fail: function (code) {
          console.fail(code);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.outbound-wrap {
  width: 100%;
  background-color: #f5f6f9;
  padding-top: 0.1rem;
  padding-bottom: 3rem;
  // height: 120vh;
  overflow: auto;
}

.outbound-extrusion {
  // height: 4.12rem;
  // height: 3.9rem;
  width: 3.39rem;
  border-radius: 0.12rem;
  overflow: hidden;
  background: linear-gradient(#ed393d, #fe958e);
  margin: auto;
  margin-bottom: 0.1rem;
}

.outbound-head {
  // height: 4rem;
  // height: 3.78rem;
  width: 3.27rem;
  margin: 0.06rem;
  background: #fff;
  border-radius: 0.12rem;
  padding-bottom: 0.2rem;
  box-sizing: border-box;
  .energy {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .energy_img {
      height: auto;
      img {
        width: 1.4rem;
        height: auto;
      }
    }
    .energy_text {
      color: #000;
      font-size: 0.12rem;
      height: 0.13rem;
      display: flex;
      align-items: center;
      background-color: rgb(102, 200, 243);
      padding: 0 0.05rem;
      box-sizing: border-box;
      .energyNum {
        margin-right: 0.1rem;
        line-height: 0.13rem;
        display: flex;
        align-items: center;
      }
      .reduction {
        line-height: 0.13rem;
        display: flex;
        align-items: center;
      }
    }
  }
  .outbound-head-leave {
    text-align: center;
    letter-spacing: 0.0035rem;
    .outbound-head-img {
      width: 0.4rem;
      height: 0.4rem;
      margin-top: 0.55rem;
    }
    .outbound-head-show {
      margin-top: 0.12rem;
      div:nth-child(1) {
        font-size: 0.18rem;
        color: #222222;
        font-weight: bold;
        height: 0.25rem;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 0.25rem;
      }

      div:nth-child(2) {
        margin-top: 0.09rem;
        color: #666666;
        font-size: 0.14rem;
        padding: 0 0.14rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 0.22rem;
        height: 0.22rem;
      }
    }
  }
  .outbound-head-button {
    text-align: center;
    margin-top: 0.59rem;
    .van-button {
      width: 1.14rem;
      height: 0.36rem;
      background: #ed171f;
      border-radius: 12px;
      border: none;
      color: #fff;
    }
  }
}
.ellipsis {
  position: fixed;
  bottom: 0rem;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 102;
  .ellipsis_box {
    position: fixed;
    bottom: 0.3rem;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    padding: 0.05rem 0.1rem;
    box-sizing: border-box;
    .ellipsis_top {
      background-color: #fff;
      border-radius: 0.08rem;
      margin-bottom: 0.1rem;
      .close_overlay {
        width: 100%;
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        border-bottom: 0.01rem solid #e7e7e7;
        font-size: 0.16rem;
      }
      .close_overlay:last-child {
        border: none;
      }
    }
    .cancel_overlay {
      width: 100%;
      height: 0.5rem;
      /deep/ .van-button {
        width: 100%;
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        background-color: #fff;
        border-radius: 0.08rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        font-size: 0.16rem;
        border: none;
      }
    }
  }
}

.bottomPopup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 102;
  .overlay {
    position: fixed;
    bottom: 0.3rem;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    padding: 0.05rem 0.1rem;
    box-sizing: border-box;
    .close_overlay {
      width: 100%;
      height: 0.5rem;
      margin-bottom: 0.1rem;
      /deep/ .van-button {
        width: 100%;
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        background-color: #fff;
        border-radius: 0.1rem;
        color: blue;
      }
    }
    .cancel_overlay {
      width: 100%;
      height: 0.5rem;
      /deep/ .van-button {
        width: 100%;
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        background-color: #fff;
        border-radius: 0.1rem;
        color: blue;
      }
    }
  }
}
.btn_box {
  width: 100%;
}
.follow_box {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  padding-top: 0.3rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
  .follow_main {
    font-size: 0.13rem;
    margin-bottom: 0.3rem;
    margin-top: 0.14rem;
    padding: 0 0.23rem;
    box-sizing: border-box;
    text-align: center;
  }
  .follow_btn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    border-top: 0.01rem solid #ccc;
    .btn {
      width: 50%;
      height: 0.5rem;
      line-height: 0.5rem;
      text-align: center;
      background-color: #fff;
      color: #ed171f;
      font-size: 0.17rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      border-right: 0.01rem solid #ccc;
    }
    .btn1 {
      width: 50%;
      height: 0.5rem;
      line-height: 0.5rem;
      text-align: center;
      color: #999999;
      background-color: #fff;
      font-size: 0.17rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }
  }
}
</style>
