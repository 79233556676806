<template>
  <div class="single-head-tabs">
    <div class="refresh-code-head-tabs_left">
      <img src="https://pageticket.tongyindigitalqpay.com/resources/images/timg@2x.png" />
      <span class="refresh-code-head-tabs_title">Metro大都会乘车码</span>
    </div>
    <div class="refresh-code-head-tabs_right">
      <div class="trip" @click="onRecord">
        <img
          class="trip_img"
          style="width: 0.18rem;"
          src="https://pageticket.tongyindigitalqpay.com/resources/images/trip.png"
        />
        <span class="trip_title">行程</span>
      </div>
      <div class="trip" @click="jumpService">
        <img
          class="trip_img"
          src="https://pageticket.tongyindigitalqpay.com/resources/images/service.png"
        />
        <span class="trip_title">客服</span>
      </div>
      <div class="open" @click="ellipsisClick">
        <van-icon name="ellipsis" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    onRecord() {
      this.$emit('onRecord');
    },
    jumpService() {
      this.$emit('jumpService');
    },
    ellipsisClick() {
      this.$emit('ellipsisClick');
    },
  },
};
</script>
<style lang="scss" scoped>
.single-head-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ed393d;
  color: #fff;
  height: 0.39rem;
  padding: 0 0.1rem;
  box-sizing: border-box;
  .refresh-code-head-tabs_left {
    display: flex;
    align-items: center;
    img {
      width: 0.18rem;
      margin-right: 0.05rem;
    }
    span:nth-child(2) {
      height: 0.39rem;
      // width: 100%;
      text-align: center;
      background: #ed393d;
      line-height: 0.39rem;
      color: #fff;
      // border-radius: 0 0 0 0.07rem;
    }
  }
  .refresh-code-head-tabs_right {
    display: flex;
    .trip {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 0.22rem;
      .trip_img {
        width: 0.16rem;
      }
      .trip_title {
        font-size: 0.12rem;
      }
    }
    .open {
    }
  }
}
</style>
