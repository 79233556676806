import Vue from 'vue';
import Router from 'vue-router';
import outboundSuccess from '../pages/singleTicket/outboundSuccess'

// const Vue = require('vue')
// const Router = require('vue-router')

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  var upgrade = getUrlParam('upgrade')
  console.log('push to: ', location)
  if(upgrade){
    //console.log('route location: ', location);
    if(!location.query){
      location.query = {};
    }

    location.query['upgrade'] = upgrade;
  }
  return routerPush.call(this, location).catch(err => err)
}

const getUrlParam = function (name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: '/mini-unionpay-ticket/',
  routes: [{
      path: '/error',
      name: 'ErrorPage',
      component: () => import('../pages/error'),
      meta: {
        title: '上海地铁',
      },
    },

    {
      path: '/',
      name: 'SinglePage', // 购票页
      component: () => import('../pages/singleTicket/single'),
      meta: {
        title: '上海地铁',
      },
    },
    {
      path: '/singleTicket/refreshCode',
      name: 'RefreshCode', // 生码
      component: () => import('../pages/singleTicket/refreshCode'),
      meta: {
        title: '上海地铁',
      },
    },

    {
      path: '/singleTicket/my',
      name: 'My', // 我的
      component: () => import('../pages/singleTicket/my'),
      meta: {
        title: '我的',
      },
    },
    {
      path: '/singleTicket/arrears',
      name: 'Arrears', // 欠款页面
      component: () => import('../pages/singleTicket/arrears'),
      meta: {
        title: '上海地铁',
      },
    },
    {
      path: '/singleTicket/rescindPage',
      name: 'RescindPage', // 解约中页面
      component: () => import('../pages/singleTicket/rescindPage'),
      meta: {
        title: '上海地铁',
      },
    },
    {
      path: '/singleTicket/warning',
      name: 'Rarning', // 网络异常页面
      component: () => import('../pages/singleTicket/warning'),
      meta: {
        title: '上海地铁',
      },
    },

    {
      path: '/singleTicket/outboundSuccess',
      name: 'OutboundSuccess', // 出站页
      // component: () => import('../pages/singleTicket/outboundSuccess'),
      component: outboundSuccess,
      meta: {
        title: '上海地铁',
      },
    },
    {
      path: '/singleTicket/haveNotTicket',
      name: 'HaveNotTicket', // 我的账单
      component: () => import('../pages/singleTicket/haveNotTicket'),
      meta: {
        title: '交易记录',
      },
    },

    {
      path: '/usageNotes/ticket',
      name: 'ticketNotes', // 使用须知页
      component: () => import('../pages/usageNotes/ticket'),
      meta: {
        title: '使用须知',
      },
    },
    {
      path: '/singleTicket/screenPage',
      name: 'ScreenPage', // 乘车记录
      component: () => import('../pages/singleTicket/screenPage'),
      meta: {
        title: '乘车记录',
      },
    },
    {
      path: '/singleTicket/official',
      name: 'Official', // 自主补票
      component: () => import('../pages/singleTicket/official'),
      meta: {
        title: '上海地铁',
      },
    },
    {
      path: '/singleTicket/unable',
      name: 'Unable', // 自主补票
      component: () => import('../pages/singleTicket/unable'),
      meta: {
        title: '补录站点',
      },
    },
    {
      path: '/singleTicket/unableOutbound',
      name: 'UnableOutbound', //
      component: () => import('../pages/singleTicket/unableOutbound'),
      meta: {
        title: '无法进站',
      },
    },

    {
      path: '/singleTicket/reauthorize',
      name: 'Reauthorize', // 乘车码警告页
      component: () => import('../pages/singleTicket/reauthorize'),
      meta: {
        title: '上海地铁',
      },
    },

    {
      path: '/singleTicket/Vacancy',
      name: 'Vacancy', // 空白页
      component: () => import('../components/Vacancy'),
      meta: {
        title: '上海地铁',
      },
    },
    {
      path: '/invoice/electronicInvoice',
      name: 'ElectronicInvoice', // 电子发票
      component: () => import('../pages/invoice/electronicInvoice'),
      meta: {
        title: '电子发票',
      },
    },
    {
      path: '/invoice/tripInvoicing',
      name: 'TripInvoicing', // 行程开票
      component: () => import('../pages/invoice/tripInvoicing'),
      meta: {
        title: '行程开票',
      },
    },
    {
      path: '/invoice/cityTicketInvoicing',
      name: 'CityTicketInvoicing', // 市域铁开票
      component: () => import('../pages/invoice/cityTicketInvoicing.vue'),
      meta: {
        title: '市域铁开票',
      },
    },
    {
      path: '/invoice/applyInvoicing',
      name: 'ApplyInvoicing', //
      component: () => import('../pages/invoice/applyInvoicing'),
      meta: {
        title: '申请开票',
      },
    },
    {
      path: '/invoice/cityTicketApplyInvoicing',
      name: 'CityTicketApplyInvoicing', //
      component: () => import('../pages/invoice/cityTicketApplyInvoicing'),
      meta: {
        title: '市域铁申请开票',
      },
    },
    {
      path: '/invoice/InvoiceHistory',
      name: 'InvoiceHistory', //
      component: () => import('../pages/invoice/invoiceHistory'),
      meta: {
        title: '开票历史',
      },
    },
    {
      path: '/invoice/InvoiceHelp',
      name: 'InvoiceHelp', //
      component: () => import('../pages/invoice/invoiceHelp'),
      meta: {
        title: '开票帮助',
      },
    },
    {
      path: '/invoice/invoiceDetail',
      name: 'InvoiceDetail', //
      component: () => import('../pages/invoice/invoiceDetail'),
      meta: {
        title: '发票详情',
      },
    },
    {
      path: '/invoice/invoiceRelation',
      name: 'InvoiceRelation', //
      component: () => import('../pages/invoice/invoiceRelation'),
      meta: {
        title: '关联行程',
      },
    },

    {
      path: '/invoice/invoiceMailbox',
      name: 'InvoiceMailbox', //
      component: () => import('../pages/invoice/invoiceMailbox'),
      meta: {
        title: '发票详情',
      },
    },
    {
      path: '/invoice/results',
      name: 'Results', //
      component: () => import('../pages/invoice/results'),
      meta: {
        title: '申请结果',
      },
    },
    {
      path: '/singleTicket/service',
      name: 'Service', //
      component: () => import('../pages/singleTicket/service'),
      meta: {
        title: '常见乘车码问题',
      },
    },{
      path: '/singleTicket/map',
      name: 'Map', //
      component: () => import('../pages/singleTicket/map'),
      meta: {
        title: '地铁地图',
      },
    },{
      path: '/singleTicket/stationMap',
      name: 'StationMap', //
      component: () => import('../pages/singleTicket/stationMap'),
      meta: {
        title: '地铁线路地图',
      },
    },{
      path: '/singleTicket/payment',
      name: 'Payment', //
      component: () => import('../pages/singleTicket/payment'),
      meta: {
        title: '补缴车费',
      },
    },{
      path: '/singleTicket/arrearsDetail',
      name: 'ArrearsDetail', //
      component: () => import('../pages/singleTicket/arrearsDetail'),
      meta: {
        title: '明细',
      },
    },{
      path: '/singleTicket/cards',
      name: 'Cards', //
      component: () => import('../pages/singleTicket/cards'),
      meta: {
        title: '卡券中心',
      },
    },{
      path: '/singleTicket/cardinfo',
      name: 'CardInfo', //
      component: () => import('../pages/singleTicket/cardinfo'),
      meta: {
        title: '卡券',
      },
    },{
      path: '/singleTicket/cardPayResult',
      name: 'CardPayResult', //
      component: () => import('../pages/singleTicket/cardPayResult'),
      meta: {
        title: '卡券支付结果',
      },
    },
    {
      path: '/ct/home',
      name: 'ct-home',
      component: () => import('../pages/ct/home'),
      meta: {
        title: '市域铁电子计次票',
      },
    },
    {
      path: '/ct/detail',
      name: 'ct-detail',
      component: () => import('../pages/ct/detail.vue'),
      props: route => ({ recordId: route.query.recordId }),
      meta: {
        title: '市域铁电子计次票',
      },
    },
    {
      path: '/ct/invalid',
      name: 'ct-invalid',
      component: () => import('../pages/ct/invalid'),
      meta: {
        title: '市域铁电子计次票',
      },
    },
    {
      path: '/ct/shop',
      name: 'ct-shop',
      component: () => import('../pages/ct/shop'),
      meta: {
        title: '购买市域铁电子计次票',
      },
    },
    {
      path: '/ct/quote',
      name: 'ct-quote',
      component: () => import('../pages/ct/quote'),
      meta: {
        title: '按站点查询票价',
      },
    },
    {
      path: '/ct/pay-result',
      name: 'ct-pay-result',
      component: () => import('../pages/ct/pay-result'),
      meta: {
        title: '购买市域铁电子计次票',
      },
    },
    {
      path: '/ct/buy-list',
      name: 'ct-buy-list',
      component: () => import('../pages/ct/buy-list'),
      meta: {
        title: '市域铁电子计次票购买记录',
      },
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});
