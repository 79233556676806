
import store from '../store'
import { Dialog } from 'vant'
import { Pboc_des_mac, strToArray } from '@/common/SHMQR/SHMTripe'
import { setLocalData, getLocalData } from '@/common/jssdk'
import { hex2base64 } from '@/common/utils'

const CT_CODE_LENGTH = 256
const CT_CODE_CACHE_KEY = 'qrcode-ct'
const CT_CODE_PROCESS_KEY = 'qrcode-ct-process-key'
const DEFAULT_CODE_PERIOD = 120
const MAX_REFRESH_COUNTS = 60000

export function useCtcode() {

  /**
   * 初始化计次票码值
   * @param {string} codeStr 缓存的码值
   * @returns {string} ctCode
   */
  function initCtcode(codeStr) {
    let ctCode = ''
    for (let index = 0; index < CT_CODE_LENGTH; index++) {
      ctCode = ctCode + '0'
    }
    if (codeStr) {
      const startIndex = 56
      const endIndex = 136
      console.log(`useCtcode...codeStr=>31: ${codeStr.substring(startIndex, endIndex)}`)
      ctCode = ctCode.substring(0, startIndex) + codeStr.substring(startIndex, endIndex) + ctCode.substring(endIndex)
      // 重置二维码刷新次数 0000 codeStr.substring(114, 118)
      ctCode = ctCode.substring(0, 114) + '0000' + ctCode.substring(118)
      console.log(`useCtcode...重置二维码刷新次数: 0000`)
    }
    console.log(`useCtcode...initCtcode: ${ctCode}`)
    return ctCode
  }

  /**
   * 调用接口刷新计次票生码
   * @param {string} deviceId 设备ID
   */
  async function refreshCtcode(deviceId) {
    const openId = localStorage.getItem('openId')
    await store.dispatch('getCtQrcodeInfo', {
      openId,
      deviceId,
    })
  }

  /**
   * 计次票缓存码 key
   * @returns {string} key
   */
  function getCtCodeKey() {
    return CT_CODE_CACHE_KEY
  }

  function getCtCodeProcessKey() {
    return CT_CODE_PROCESS_KEY
  }

  /**
   * 缓存计次票生码信息
   * @param {string} key 缓存key
   * @param {Object} data 缓存数据
   * @param {string} data.qrCode 码值
   * @param {string} data.processKey 过程密钥
   */
  async function setCacheCtcode(key, data) {
    await setLocalData({ key: key, value: data })
  }

  /**
   * 获取计次票生码信息
   * @param {string} key 缓存key
   * @returns {Object} data 缓存数据
   * @returns {string} data.qrCode 码值
   * @returns {string} data.processKey 过程密钥
   * @returns {string} data.appUserId 用户ID
   */
  async function getCacheCtcode(key) {
    const [data] = await getLocalData({ key: key })
    return data
  }

  /**
   * 组装计次票码值
   * @param {string} codeStr 码值
   * @param {string} cacheKey 缓存key
   * @param {string} processKey 过程密钥
   * @param {boolean} isCountAdd 刷新次数是否+1
   * @param {boolean} isRewrite 有效期是否需要减去刷新时间
   * @returns {boolean} 是否成功
   */
  async function buildCtCode(codeStr, cacheKey, processKey, isCountAdd = false, isRewrite = false) {
    // 获取二维码请求时间
    const tmpData = codeStr.substring(232, 240);
    const request_time = parseInt(tmpData, 16);
    // 计算分散因子的过期时间
    const tmp_time = getFactorTime(codeStr);
    const period_time = request_time + tmp_time * 60;
    // 当前时间
    const current_seconds = getCurrentSeconds()
    if (current_seconds + 120 < request_time) {
      console.log('系统时间小于请求时间, current_seconds: ' + current_seconds + ", tmpData: " + tmpData + ", request_time: " + request_time);
      Dialog.confirm({
        title: '提示',
        message: '手机时间异常，请调整后重试',
        showCancelButton: false,
        confirmButtonText: '确认'
      }).then(() => {
        console.log('确认');
      })
      return false;
    }
  
    let tmp_period = period_time - DEFAULT_CODE_PERIOD;
    
    if (isRewrite) {
      console.log('回写时二维码有效期不减去刷新时间');
      tmp_period = period_time;
    }
  
    if (current_seconds >= tmp_period) {
      console.log('分散因子过期');
      return false;
    }

    // 获取当前的二维码刷新次数
    let refreshCounts = parseInt(codeStr.substring(114, 118), 16);
    if (isCountAdd) {
      refreshCounts++;
    }
  
    if (refreshCounts >= MAX_REFRESH_COUNTS) {
      console.log('当前二维码刷新次数超出限制 请重新初始化 refreshCounts:', refreshCounts);
      return false;
    }
    refreshCounts = refreshCounts.toString(16);

    if (refreshCounts.length == 1) {
      refreshCounts = `000${refreshCounts}`;
    } else if (refreshCounts.length == 2) {
      refreshCounts = `00${refreshCounts}`;
    } else if (refreshCounts.length == 3) {
      refreshCounts = `0${refreshCounts}`;
    }
  
    if (refreshCounts.length == 4) {
      codeStr = await qrCodeItemPush(codeStr, cacheKey, 114, refreshCounts);
    } else {
      codeStr = await qrCodeItemPush(codeStr, cacheKey, 114, '0001');
    }
  
    const index = 224;
    // 计算当前二维码的有效期
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, period_time.toString(16));
    codeStr = refreshappAuthCode(codeStr, cacheKey, processKey);
    console.log(`buildCtCode...刷新后的码值:${codeStr}`);
    return codeStr
  }

  /**
   * 获取分散因子有效时间
   * @param {string} codeStr 
   * @returns {number} 分散因子有效时间(分钟)
   */
  function getFactorTime(codeStr) {
    const low_time = parseInt(codeStr.substr(246, 2), 16);
    const high_time = parseInt(codeStr.substr(245, 1), 16);
    const tmp_time_high = high_time * 256;
    const tmp_time = low_time + tmp_time_high;
    console.log('分散因子有效时间为(分钟): ', tmp_time);
    return tmp_time;
  }

  /**
   * 获取当前时间(秒)
   * @returns {number} 当前时间
   */
  function getCurrentSeconds() {
    const timestamp = Date.parse(new Date());
    const currentSeconds = parseInt(timestamp) / 1000 + 8 * 60 * 60;
    return currentSeconds;
  }

  /**
   * 更新计次票缓存码值
   * @param {string} codeStr 码值
   * @param {string} key 缓存码key
   * @param {number} index 缓存码偏移量 要更新的位置
   * @param {string} value 缓存码值  更新的值
   * @returns {string} 更新后的码值
   */
  async function qrCodeItemPush(codeStr, key, index, value) {
    const newCodeStr = codeStr.substring(0, index) + value + codeStr.substring(index + value.length);
    const data = {
      qrCode: newCodeStr,
    }
    await setCacheCtcode(key, data)
    return newCodeStr;
  }

  /**
   * 更新计次票缓存码
   * @param {string} codeStr 码值
   * @param {number} index 缓存码偏移量
   * @param {string} changeStr 缓存码值
   * @returns {string} 更新后的码值
   */
  // function changeStr(codeStr, index, changeStr) {
  //   return codeStr.substring(0, index) + changeStr + codeStr.substring(index + changeStr.length);
  // }

  /**
   * 获取appAuthCode
   * @param {string} codeStr 
   * @param {string} cacheKey 
   * @param {string} processKey 
   * @returns {string} 新的码值
   */
  async function refreshappAuthCode(codeStr, cacheKey, processKey) {
    codeStr = codeStr.toLowerCase();
    const index = CT_CODE_LENGTH - 8;
    const authCode = getMacBySrc(codeStr.substring(0, index), processKey);
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, authCode);
    return codeStr
  }

  /**
   * 获取MAC
   * @param {string} src 
   * @param {string} processKey 
   * @returns {string} MAC
   */
  function getMacBySrc(src, processKey) {
    if(!processKey){
      return ''
    }
    const result = Pboc_des_mac(strToArray(processKey), strToArray(src)).join('');
    console.log(`getMacBySrc: (${src}), result: ${result}, processKey: ${processKey}`);
    return result;
  }


  /**
   * 
   * @param {string} codeStr 码值
   * @param {string} cacheKey 缓存key
   * @param {Map} infoMap 码数据
   * @param {boolean} isIOS 是否是苹果
   * @returns 
   */
  async function generateCtCode(codeStr, cacheKey, infoMap, isIOS) {
    console.log(`开始组装码值...infoMap: ${infoMap}`);
    if (infoMap == null || !infoMap.get('bluetoothAddress')) {
      console.log('码数据为空，直接返回');
      return false;
    }
  
    // 安卓 00 苹果 01
    const osType = isIOS ? '01' : '00';
    let index = 0;
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, osType);
    console.log('osType: ', osType);
    index += osType.length;
  
    const bluetoothAddress = infoMap.get('bluetoothAddress');
    // 手机号
    if (bluetoothAddress.length != 12) {
      console.log('手机蓝牙地址（bluetoothAddress）格式非法 请检查 = ', bluetoothAddress);
      return false;
    }
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, bluetoothAddress);
    index += bluetoothAddress.length;
  
    // 用户Token
    const userToken = infoMap.get('userToken');
    if (userToken.length != 20) {
      console.log('帐户userToken格式有误 请检查 = ', userToken);
      return false;
    }
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, userToken);
    index += userToken.length;
  
    // 账户认证码
    const certCode = infoMap.get('certCode');
    if (certCode.length != 8) {
      console.log('账户认证码certCode格式有误 请检查 = ', certCode);
      return false;
    }
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, certCode);
    index += certCode.length;
  
    // 发卡方mac
    const cardMac = infoMap.get('cardMac');
    if (cardMac.length != 8) {
      console.log('发卡方cardMac格式有误 请检查 = ', cardMac);
      return false;
    }
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, cardMac);
    index += cardMac.length;
  
    // 车站位置
    const location = infoMap.get('location');
    if (location.length != 4) {
      console.log('车站位置location格式有误 请检查 = ', location);
      return false;
    }
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, location);
    index += location.length;
  
    // 卡类型
    const cardType = infoMap.get('cardType');
    if (cardType.length != 2) {
      console.log('卡类型格式cardType有误 请检查 = ', cardType);
      return false;
    }
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, cardType);
    index += cardType.length;

    // 处理进出站数据 二维码 29-57 字节
  
    // 过程mac
    index = 106;
    const dataMac = infoMap.get('dataMac');
    if (dataMac.length != 8) {
      console.log('dataMac格式有误 请检查 = ', dataMac);
      return false;
    }
    if (codeStr.substring(106, 114) == '00000000') {
      codeStr = await qrCodeItemPush(codeStr, cacheKey, index, dataMac);
      index += dataMac.length;
    }
    console.log('dataMac', dataMac)
  
    // 车票子类型
    index = 136;
    const ticketType = infoMap.get('ticketType');
    if (!ticketType || ticketType.length != 2) {
      console.log('车票子类型格式有误 ticketType 请检查 = ', ticketType);
      return false;
    }
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, ticketType);
    index += ticketType.length;
  
    // 车票启用时间
    const activeTime = infoMap.get('activeTime');
    if (activeTime.length != 8) {
      console.log('车票启用时间有误 activeTime 请检查 = ', activeTime);
      return false;
    }
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, activeTime);
    index += activeTime.length;
  
    // 线路位图/车站位图1
    const stationBitmap1 = infoMap.get('stationBitmap1');
    if (stationBitmap1.length != 8) {
      console.log('线路位图/车站位图1 stationBitmap1 请检查 = ', stationBitmap1);
      return false;
    }
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, stationBitmap1);
    index += stationBitmap1.length;
  
    // 线路位图/车站位图1
    const stationBitmap2 = infoMap.get('stationBitmap2');
    if (stationBitmap1.length != 8) {
      console.log('线路位图/车站位图2 stationBitmap2 请检查 = ', stationBitmap2);
      return false;
    }
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, stationBitmap2);
    index += stationBitmap2.length;
  
    // 范围标志
    const rangTag = infoMap.get('rangTag');
    if (rangTag.length != 2) {
      console.log('范围标志/范围标志 rangTag 请检查 = ', rangTag);
      return false;
    }
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, rangTag);
    index += rangTag.length;
  
    // 有效使用时间
    const effectiveHour = infoMap.get('effectiveHour');
    // 计次票 位数4
    if (effectiveHour.length != 4) {
      console.log(`有效使用时间effectiveHour生码部分有误 请检查effectiveHour:  ${effectiveHour}`);
      return false;
    }
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, effectiveHour);
    index += effectiveHour.length;
  
    // 预留
    index = 216;
    const extendMac = infoMap.get('extendMac');
    if (extendMac.length != 8) {
      console.log('预留extendMac请检查 = ', extendMac);
      return false;
    }
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, extendMac);
    index += extendMac.length;
  
    index = 232;
    // 分散因子
    const factor = infoMap.get('factor');
    if (factor.length != 16) {
      console.log('分散因子factor格式有误 请检查 = ', factor);
      return false;
    }
    codeStr = await qrCodeItemPush(codeStr, cacheKey, index, factor);
    index += factor.length;
  
    // processKey
    const processKey = infoMap.get('processKey');
    if (processKey.length != 32) {
      console.log('processKey格式有误 请检查 = ', processKey);
      return false;
    }
    console.log('processKey', processKey)
    codeStr = await buildCtCode(codeStr, cacheKey, processKey, true, false);
    console.log(`generateCtCode...二维码码值组装完成...codeStr: ${codeStr}`);
    return codeStr;
  }

  function getBroadCast(codeStr) {
    if (!codeStr || codeStr.length != CT_CODE_LENGTH) {
      console.log('获取广播失败');
      return false;
    }
    console.log('getBroadCast broadCast = ', codeStr.substr(2, 12));
    return codeStr.substr(2, 12).toUpperCase();
  }

  /**
   * 计算34数据
   * @param {string} codeStr 
   * @param {string} processKey 
   * @returns {string}
   */
  async function calculateData34(codeStr, processKey) {
    const accountAuthCode = codeStr.substring(34, 42);
    const macStr = getMacBySrc(accountAuthCode, processKey);
    console.log(`calculateData34...macStr: ${macStr}`)
    console.log(`calculateData34...000934${accountAuthCode}${macStr}`)
    const data34 = hex2base64(`000934${accountAuthCode}${macStr}`);
    console.log(`calculateData34...hex2base64...calculateData34: ${data34}`);
    return data34;
  }

  /**
   * 回写31数据
   * @param {string} data31 
   * @param {string} codeStr 
   */
  async function writeBackData31(data31, codeStr, cacheKey) {
    console.log(`writeBackData31...二维码码值: ${codeStr}...data31: ${data31}`);
    const data = data31.substr(32, 80);
    console.log(`writeBackData31...31数据中截取80位: ${data}`);
    const newCodeStr = await qrCodeItemPush(codeStr, cacheKey, 56, data);
    console.log(`writeBackData31...翻码之后的二维码值: ${newCodeStr}`)
    return newCodeStr
  }

  /**
   * 重置广播信号
   * @param {string} codeStr 
   * @param {string} cacheKey 
   * @param {string} processKey 
   */
  async function changeBroadcast(codeStr, cacheKey, processKey) {
    console.log(`use-ctcode...重置广播信号之前...codeStr: ${codeStr}`);
    if (codeStr && !codeStr.startsWith("000000000")) {
      const oldBroadcast = codeStr.substr(2, 12);
      const newBroadcast = (parseInt(oldBroadcast, 16) + 10000000000).toString(16).toUpperCase();
      console.log(`use-ctcode...oldBroadcast: ${oldBroadcast}, newBroadcast: ${newBroadcast}`);
      codeStr = codeStr.substr(0, 2) + newBroadcast + codeStr.substr(14);
      codeStr = await refreshappAuthCode(codeStr, cacheKey, processKey);
      console.log('use-ctcode...完成重置广播信号', codeStr);
    }
  }

  return {
    getCtCodeKey,
    getCtCodeProcessKey,
    initCtcode,
    refreshCtcode,
    setCacheCtcode,
    getCacheCtcode,
    buildCtCode,
    generateCtCode,
    getBroadCast,
    calculateData34,
    writeBackData31,
    getMacBySrc,
    getCurrentSeconds,
    changeBroadcast,
  }
}