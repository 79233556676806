/**
 * actions
 */
import {
  getCode,
  getRefresh,
  getCtQrCode,
  saveRecordLog
} from '../utils/api';
import router from '../router';
import {
  getIn,
  getDateHour
} from '../utils';
import {
  setLocalData,
  getLocalData
} from '@/common/jssdk'
import {
  getInitSHMQR1
} from '@/common/SHMQR/SHMQRCode'
import {
  Dialog,
  Toast
} from 'vant';
import {
  setShmQr
} from '../common/SHMQR/SHMQRCode';
import {
  state
} from './state';
import { useCtcode } from '@/utils/use-ctcode'

const ctCode = useCtcode()

export const actions = {
 
  async getCode({
    state,
    commit
  }, payload) {

    const {
      code = ''
    } = payload
    const res = await getCode(
      code,
    );
    console.log('返回信息', res)
    if (res.status == 9999) {
      const CodeInfo = res.data


      commit('changeState', {
        CodeInfo,
      });

    } else {
      // router.history.replace({
      //   name: 'ErrorPage',
      // });
    }

  },
  async getQrcodeInfo({ state, commit }, payload) {
    let [SHMQR] = await getLocalData({
      key: 'qrcodedaySHMQR',
    });

    let currentAppUserId = localStorage.getItem('appUserId')
    if (SHMQR && SHMQR.qrCode && !SHMQR.qrCode.startsWith('00000000') && SHMQR.appUserId && SHMQR.appUserId == currentAppUserId) {
      console.log('找到缓存中SHMQR');
      //return;

    } else {
      console.log('缓存没有匹配的SHMQR, currentAppUserId: ' + currentAppUserId, SHMQR);
      SHMQR = false;
    }

    const {
      openId = '',
    } = payload
    var curQrCode = SHMQR.qrCode
    console.log('curQrCode',curQrCode)
    console.log('openId',openId)
    var parmas={
      openId:openId,
      curQrCode:curQrCode
    }
    state.isAbnormal = false
    state.sameStationInOutShow = false
    state.prohibit=false
    const res = await getRefresh(
      parmas
    );
    // eslint-disable-next-line no-console
    console.log('请求生码接口返回数据', res);
    console.log('请求生码接口返回数据', res.status);
    console.log(`请求生码接口 time：${new Date().getTime()}`);
    // res.status = 3530
    // res.msg = '您有待补缴订单，请补缴!'
    // res.msg='请联系客服处理：64370000-8-3'
    state.msg=res.msg
    state.status=res.status
    if (res.status == 9999) {
      const queryInfo = res.data

      let lastQrcode = null;
      if(queryInfo && queryInfo.lastQrcode){
        
        if(queryInfo.lastQrcode.length == 256){
          lastQrcode = queryInfo.lastQrcode
          console.log('lastQrcode: '+lastQrcode);
        }else if(queryInfo.lastQrcode == 'RESET'){
          console.warn('lastQrcode=RESET, 需要重置二维码')
          SHMQR = null
        }

      }
      if(queryInfo.sameStationInOut && queryInfo.sameStationInOut=='1'){
        state.sameStationInOutShow =true
        console.log('显示“无票乘车可按网络单程最高票价补收5倍票款”')
      }
      
      var SHM_QR = null;
      if (!SHMQR) {
        if(lastQrcode){
          SHM_QR = lastQrcode;
          console.log('使用服务端缓存二维码'); 
        }else{
          SHM_QR = getInitSHMQR1();
          console.log('重新初始化二维码'); 
        }
        
        setLocalData({
          key: 'qrcodedaySHMQR',
          value: {
            qrCode: SHM_QR,
            openId: localStorage.getItem('openId')
          },
        });
      } else {
        SHM_QR = SHMQR.qrCode
        
        // 检查服务端回送的上次二维码
        if(lastQrcode){
          
          if(SHM_QR.substr(14,20) != queryInfo.accountToken){
            console.warn("本地二维码["+lastQrcode.substr(14,20)+"]地铁宝ID与服务端不一致, 复用服务端二维码")
            SHM_QR = lastQrcode
          }else{
            let serverCodeCount = parseInt(lastQrcode.substr(114,4), 16)
            let localCodeCount = parseInt(SHM_QR.substr(114,4), 16)
            
            if(serverCodeCount > localCodeCount){
              console.warn("本地二维码次数["+localCodeCount+"]小于服务端二维码次数"+serverCodeCount)
              SHM_QR = lastQrcode
            }
          }
          
        }else{
          console.log('复用原二维码', SHM_QR);
        }
      }

      var dateHour = getDateHour();
      setLocalData({
        key: 'qrcodedaySHMQRDateHour',
        value: {
          date: dateHour.date,
          hour: dateHour.hour,
          time: dateHour.time
        },
      });

      // 刷新二维码更新 SHM_QR，否则无法出站（补进站失败）
      setShmQr(SHM_QR);
      commit('changeState', {
        queryInfo,
      });

      commit('changeState', {
        dayOrAsingle: false,
      });
    } else if (res.status === 7001) {
      localStorage.setItem('codeStatus', res.status)
      router.history.replace({
        name: 'Arrears',
      });
    } else if (res.status === 7002) {
      state.isAbnormal = true
      Dialog.confirm({
        title: '提示',
        message: res.msg,
        showCancelButton: false,
        confirmButtonText: '知道了'
      }).then(() => {
        console.log('关闭')
      })
    } else if (res.status === 8001) {
      console.log('返回状态码为8001，需要跳转到首页')
      localStorage.removeItem('openId')
      Dialog.confirm({
        title: '提示',
        message: res.msg,
        showCancelButton: false,
        confirmButtonText: '知道了'
      }).then(() => {
        console.log('关闭')
        
      })
    } else if (res.status === 8002) {
      router.history.replace({
        name: 'RescindPage',
      });
     
    } else if(res.status === 3530){
      state.prohibit = true
      console.log('开始禁码')
      Dialog.confirm({
        title: '提示',
        message: res.msg,
        showCancelButton: false,
        confirmButtonText: '知道了'
      }).then(() => {
        console.log('关闭')
        state.prohibit = false
        localStorage.removeItem('openId')
        router.history.replace({
          name: 'SinglePage',
        });
      })
       
    }else if(res.status === 5008){
      console.log('开始禁码')
     
    }else {
      state.isAbnormal = true
      Dialog.confirm({
        title: '提示',
        message: res.msg,
        showCancelButton: false,
        confirmButtonText: '知道了'
      }).then(() => {
        console.log('关闭')
        localStorage.removeItem('openId')
        router.history.replace({
          name: 'SinglePage',
        });
      })

    }
  },
  // 计次票生码
  async getCtQrcodeInfo({ state, commit }, payload) {
    const { openId = '', deviceId } = payload
    console.log('openId', openId)
    const parmas = {
      openId: openId,
      deviceId: deviceId,
      bankId: 'YUNSHANFU',
    }
    const res = await getCtQrCode(parmas)
    console.log('请求计次票生码接口返回数据', res)
    // 更新 state
    commit('changeState', { ctCodeInfo: res.data })
    if (res.status == 9999) {
      const ctCodeKey = ctCode.getCtCodeKey()
      // 获取缓存的计次票生码信息
      const codeData = await ctCode.getCacheCtcode(ctCodeKey)
      const codeStr = codeData.qrCode
      console.log(`获取缓存的计次票生码信息...codeStr: ${codeStr}`)
      // 联机生码初始化码值
      const tempCtCode = ctCode.initCtcode(codeStr)
      console.log(`计次票联机生码成功...tempCtCode: ${tempCtCode}`)
      
      // 缓存计次票 processKey
      const processKey = res.data.processKey
      const cacheProcessKey = ctCode.getCtCodeProcessKey()
      localStorage.setItem(cacheProcessKey, processKey)
      console.log(`cacheProcessKey: ${cacheProcessKey}...processKey: ${processKey}`)
      
      // 缓存计次票生码信息
      const data = {
        qrCode: tempCtCode,
      }
      await ctCode.setCacheCtcode(ctCodeKey, data)
    }
  },
};
