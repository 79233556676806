<script>
import dayjs from 'dayjs'
import { newsUrl, matomoOpen } from '@/unified'
import { EventBus } from '@/common/event-bus.js'
import { jumpPage } from '@/utils'

export default {
  name: 'ad-text',
  props: {
    channelId: {
      type: String,
      default: '',
    },
    refresh: {
      type: String,
      default: '',
    },
    enableDot: {
      type: Boolean,
      default: false,
      description: '是否开启红点显示和统计功能'
    },
    maxClickDays: {
      type: Number,
      default: 15,
      description: '最大点击天数，达到后不再显示红点'
    }
  },
  data () {
    return {
      adUrls: [],
      showDot: false,
      enableHCXAd: false
    }
  },
  beforeDestroy() {
    EventBus.$off('PageShow')
  },
  mounted() {
    this.checkDotStatus()
    EventBus.$on('PageShow', msg => {
      this.enableHCXAd = '1' == localStorage.getItem('enableHCXAd');
      if (this.enableHCXAd) {
        this.getNewsList();
      }
    })
    this.enableHCXAd = '1' == localStorage.getItem('enableHCXAd');
    if (this.enableHCXAd) {
      this.getNewsList();
    }
  },
  methods: {
    getNewsList() {
      var time = new Date().getTime();
      var newDate = dayjs(time).format('YYYYMMDDHH');
      this.$axios({
        method: 'get',
        url: newsUrl + '/api/news/list?channelId=' + this.channelId + '&_=' + newDate
      }).then(response => {
        this.adUrls = response.data.data
        console.log('文字广告内容：', this.adUrls)
      }).catch(error => {
        console.log(error)
      })
    },
    checkDotStatus() {
      if (!this.enableDot) {
        this.showDot = false
        return
      }

      const key = `ad_dot_${this.channelId}`
      const clickKey = `ad_click_days_${this.channelId}`
      const lastClickTime = localStorage.getItem(key)
      const today = dayjs().format('YYYY-MM-DD')

      // 检查月份是否变化
      if (lastClickTime) {
        const lastMonth = dayjs(lastClickTime).format('YYYY-MM')
        const currentMonth = dayjs(today).format('YYYY-MM')
        if (lastMonth !== currentMonth) {
          // 如果月份不同，删除点击统计
          localStorage.removeItem(clickKey)
        }
      }
      
      const clickDays = parseInt(localStorage.getItem(clickKey) || '0')
      
      // 如果点击天数已达到配置的最大天数，不显示红点
      if (clickDays >= this.maxClickDays) {
        this.showDot = false
        return
      }
      
      if (!lastClickTime || lastClickTime !== today) {
        this.showDot = true
      }
    },
    handleClick() {
      const key = `ad_dot_${this.channelId}`
      const clickKey = `ad_click_days_${this.channelId}`
      const lastClickTime = localStorage.getItem(key)
      const today = dayjs().format('YYYY-MM-DD')
      
      if (this.enableDot) {
        // 如果是新的一天点击，增加点击天数
        if (!lastClickTime || lastClickTime !== today) {
          const clickDays = parseInt(localStorage.getItem(clickKey) || '0')
          localStorage.setItem(clickKey, (clickDays + 1).toString())
        }
        
        this.showDot = false
        localStorage.setItem(key, today)
      }
    },
    jumpIconUrl(item) {
      console.log(item);
      this.handleClick()

      if (matomoOpen && this.$matomo) {
        this.$matomo.trackEvent('adClick', item.id);
      }

      if (item.href) {
        var url = new URL(item.href);
        console.log('打开广告页面: ' + item.href);
        if (url.protocol != 'https:' && url.protocol != 'http:') {
          jumpPage(item, this.$router);
        } else {
          if (upsdk.createWebView && !item.innerLink) {
            try {
              upsdk.createWebView({
                url: item.href,
                isFinish: 0,
              });
            } catch (e) {
              this.openWithLocation(item);
            }
          } else {
            this.openWithLocation(item);
          }
        }
      }
    },
    openWithLocation(item) {
      if (this.refresh) {
        console.log('是乘车页面');
        this.$emit('bleStop');
      }
      window.location.href = item.href;
    },
  },
  watch: {
    refresh: {
      handler() {
        this.checkDotStatus()
      }
    }
  }
}
</script>

<template>
  <div class="ad-text">
    <van-swipe v-if="adUrls.length > 0" class="text-swipe" :autoplay="5000">
      <van-swipe-item @click="jumpIconUrl(ad)" class="swipe_item" v-for="ad in adUrls" :key="ad.id">
        <van-badge :dot="showDot">
          <a href="javascript:void(0)">{{ad.title}}</a>
        </van-badge>
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator"></div>
      </template>
    </van-swipe>
  </div>
</template>

<style lang="css" scoped>
.ad-text {
  width: 100%;
}
.text-swipe {
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  font-size: 0.13rem;
  color: #333333;
}
</style>

<style lang="css">
.van-badge--dot {
  top: 0.14rem;
  right: -5px;
}
</style>