/**
 * getters
 */

import {
  state
} from './state';
import { strToHexCharCode } from '@/common/SHMQR/SHMUtil';

export const getters = {
  auth: state => state.auth,
  CodeInfo: state => state.CodeInfo,
  opebId: state => state.opebId,
  isAbnormal: state => state.isAbnormal,
  sameStationInOutShow: state => state.sameStationInOutShow,
  prohibit: state => state.prohibit,
  status: state => state.status,
  msg: state => state.msg,
  list: state => state.list,
  ticket: state => state.ticket,
  queryInfo: state => state.queryInfo,
  logAdd: state => state.logAdd,
  // 日票
  dayQrcodeInfo: state => state.dayQrcodeInfo,

  dayOrAsingle: state => state.dayOrAsingle,

  // 计次票相关
  ctCodeInfo: state => state.ctCodeInfo,
  ctQrCodeMap: state => {
    const data = state.ctCodeInfo;
    console.log('从store中获取到的计次票生码数据: ' + JSON.stringify(data));
    const map = new Map();
    if (data && data.mobile) {
      console.log('bluetoothAddress = strToHexCharCode(res.mobile) = ' + strToHexCharCode(data.mobile));
      let currentBroadcast = '';
      if (!currentBroadcast) {
        currentBroadcast = strToHexCharCode(data.mobile);
      } else {
        console.log("保留使用原广播信号: " + currentBroadcast);
      }
      map.set('bluetoothAddress', currentBroadcast);
      map.set('userToken', data.accountToken);
      map.set('certCode', data.accountCertCode);
      map.set('cardMac', data.channelMac);
      map.set('location', 'FFFF');
      map.set('cardType', data.cardType);
      map.set('dataMac', data.dataMac);
      map.set('factor',  data.factor);
      map.set('processKey', data.processKey);
      map.set('ticketType', data.cardChildType || '00');
      map.set('activeTime', data.activeTime || '00000000');
      map.set('stationBitmap1', data.stationCode1 || '00000000');
      map.set('stationBitmap2', data.stationCode2 || '00000000');
      map.set('rangTag', data.rangeMark || '00');
      map.set('effectiveHour', data.vaildHours || '0000');
      map.set('extendMac', data.extendMac || '00000000');
    }
    return map;
  }
};
